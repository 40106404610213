import React, { useState } from "react";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/pricing/Hero";
import Pricing from "../sections/pricing/Pricing";

const PricingPage = () => {
  const [plan, setPlan] = useState('yearly');
  return (
    <>
      <PageWrapper
        themeConfig={{
          headerClassName: "site-header--menu-right",
          headerButton: (
            <>
            <a href="https://app.intry.io/signup"><button className="btn btn btn-blue-3 header-btn rounded-5">
              Sign up
            </button></a>
            </>
          ),
          footerStyle: "style3",
        }}
      >
        <Hero plan={plan} setPlan={setPlan} />
        <Pricing plan={plan} setPlan={setPlan} />
      </PageWrapper>
    </>
  );
};
export default PricingPage;
