import React from "react";

const Pricing = ({ plan, setPlan = () => {}}) => {
  return (
    <div className="pb-5 pb-md-11 pb-lg-19">
      <div className="container">
        <div className="row justify-content-center">
          {/* Single Table */}
          <div className="col-xl-6 col-lg-5 col-md-6 col-sm-8">
            <div
              className="border rounded-10 text-center px-7 px-lg-16 pt-10 pb-13 gr-hover-2 mb-9"
              data-aos="fade-up"
              data-aos-duration={300}
              data-aos-once="true"
            >
              <p className="text-dodger-blue-1 font-size-5 mb-9">Basic</p>
              <h2 className="font-size-11 mb-1">FREE</h2>
              <span className="font-size-5 mb-2">per month {plan === 'yearly' && 'paid yearly'}</span><br/>
              {/* <span className="font-size-5 mb-2">$0 per seat forever</span> */}
              <ul className="list-unstyled font-size-5 line-height-50 heading-default-color pt-8">
                <li className="mb-7">5 Users</li>
                <li className="mb-7">1GB Storage</li>
                <li className="mb-7">Unlimited Goals</li>
                <li className="mb-7">Unlimited Teams</li>
                <li className="mb-7">Unlimited Outcomes</li>
                <li className="mb-7">Real Time Analytics</li>
                <li className="mb-7">Jira Integration</li>
                <li className="mb-7">Email Support</li>
              </ul>
              <div className="pt-7 pt-lg-17">
                <a className="btn btn-blue-3 btn-2 rounded-5" href="https://app.intry.io/signup">
                  Get it now!
                </a>
              </div>
            </div>
          </div>
          {/* End Single Table */}
          {/* Single Table */}
          <div className="col-xl-6 col-lg-5 col-md-6 col-sm-8">
            <div
              className="border rounded-10 text-center px-7 px-lg-16 pt-10 pb-13 gr-hover-2 mb-9"
              data-aos="fade-up"
              data-aos-duration={300}
              data-aos-once="true"
            >
              <p className="text-dodger-blue-1 font-size-5 mb-9">Startup</p>
              <h2 className="font-size-11 mb-1">{plan === 'yearly' ? '$40' : '$50'}</h2>
              <span className="font-size-5 mb-2">per month {plan === 'yearly' && 'paid yearly'}</span><br/>
              {/* <span className="font-size-5 mb-2">{plan === 'yearly' ? '$1.6' : '$2'} per seat</span> */}
              <ul className="list-unstyled font-size-5 line-height-50 heading-default-color pt-8">
                <li className="mb-7">25 Users</li>
                <li className="mb-7">25GB Storage</li>
                <li className="mb-7">Unlimited Goals</li>
                <li className="mb-7">Unlimited Teams</li>
                <li className="mb-7">Unlimited Outcomes</li>
                <li className="mb-7">Real Time Analytics</li>
                <li className="mb-7">Jira Integration</li>
                <li className="mb-7">Email Support</li>
              </ul>
              <div className="pt-7 pt-lg-17">
                <a className="btn btn-blue-3 btn-2 rounded-5" href="https://app.intry.io/signup">
                  Get it now!
                </a>
              </div>
            </div>
          </div>
          {/* End Single Table */}
          {/* Single Table */}
          <div className="col-xl-6 col-lg-5 col-md-6 col-sm-8">
            <div
              className="border rounded-10 text-center px-7 px-lg-16 pt-10 pb-13 gr-hover-2 mb-9"
              data-aos="fade-up"
              data-aos-duration={300}
              data-aos-once="true"
            >
              <p className="text-dodger-blue-1 font-size-5 mb-9">Business</p>
              <h2 className="font-size-11 mb-1">{plan === 'yearly' ? '$160' : '$200'}</h2>
              <span className="font-size-5 mb-2">per month {plan === 'yearly' && 'paid yearly'}</span><br/>
              {/* <span className="font-size-5 mb-2">{plan === 'yearly' ? '$1.6' : '$2'} per seat</span> */}
              <ul className="list-unstyled font-size-5 line-height-50 heading-default-color pt-8">
                <li className="mb-7">100 Users</li>
                <li className="mb-7">100GB Storage</li>
                <li className="mb-7">Unlimited Goals</li>
                <li className="mb-7">Unlimited Teams</li>
                <li className="mb-7">Unlimited Outcomes</li>
                <li className="mb-7">Real Time Analytics</li>
                <li className="mb-7">Jira Integration</li>
                <li className="mb-7">Email Support</li>
              </ul>
              <div className="pt-7 pt-lg-17">
                <a className="btn btn-blue-3 btn-2 rounded-5" href="https://app.intry.io/signup">
                  Get it now!
                </a>
              </div>
            </div>
          </div>
          {/* End Single Table */}
          {/* Single Table */}
          <div className="col-xl-6 col-lg-5 col-md-6 col-sm-8">
            <div
              className="border rounded-10 text-center px-7 px-lg-16 pt-10 pb-13 gr-hover-2 mb-9"
              data-aos="fade-up"
              data-aos-duration={300}
              data-aos-once="true"
            >
              <p className="text-dodger-blue-1 font-size-5 mb-9">Enterprise</p>
              <h2 className="font-size-11 mb-1">{plan === 'yearly' ? '$400' : '$500'}</h2>
              <span className="font-size-5 mb-2">per month {plan === 'yearly' && 'paid yearly'}</span><br/>
              {/* <span className="font-size-5 mb-2">{plan === 'yearly' ? '$1.6' : '$2'} per seat</span> */}
              <ul className="list-unstyled font-size-5 line-height-50 heading-default-color pt-8">
                <li className="mb-7">250 Users</li>
                <li className="mb-7">250GB Storage</li>
                <li className="mb-7">Unlimited Goals</li>
                <li className="mb-7">Unlimited Teams</li>
                <li className="mb-7">Unlimited Outcomes</li>
                <li className="mb-7">Real Time Analytics</li>
                <li className="mb-7">Jira Integration</li>
                <li className="mb-7">Email Support</li>
              </ul>
              <div className="pt-7 pt-lg-17">
                <a className="btn btn-blue-3 btn-2 rounded-5" href="https://app.intry.io/signup">
                  Get it now!
                </a>
              </div>
            </div>
          </div>
          {/* End Single Table */}
        </div>
      </div>
    </div>
  );
};

export default Pricing;
