import React from "react";

const Hero = ({plan, setPlan = ()=>{}}) => {
  return (
    <div className="pt-23 pt-md-26 pt-lg-28" style={{background: 'linear-gradient(to bottom, var(--bg-3), #ffffff)'}}>
      <div className="container">
        {/* Section Padding */}
        <div className="row justify-content-center">
          <div className="col-xl-7 col-lg-8 col-md-11">
            <div
              className="text-center mb-6"
              data-aos="fade-up"
              data-aos-duration={500}
              data-aos-delay={500}
            >
              <h2 className="font-size-11 mb-7">Pricing &amp; Plan</h2>
              <p className="font-size-7 mb-0">
                Choose the right plan for your organization<br/>
              </p>
            </div>
            <div
                className="text-center btn-section mb-10 mb-lg-23"
                data-aos="fade-down"
                data-aos-delay={500}
              >
                <div className="btn-toggle-square active mx-3 price-deck-trigger rounded-10 bg-blue-3">
                  <button
                    className={`text-break btn-reset focus-reset ${plan ===
                      "monthly" && "active text-white"}`}
                    onClick={() => setPlan("yearly")}
                  >
                    Yearly
                  </button>
                  <button
                    className={`text-break btn-reset focus-reset ${plan ===
                      "yearly" && "active text-white"}`}
                    onClick={() => setPlan("monthly")}
                  >
                    Monthly
                  </button>
                </div>
              </div>
          </div>
        </div>
        {/* End Section Padding */}
      </div>
    </div>
  );
};

export default Hero;
